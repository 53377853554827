<template>
  <div>
    <!-- <h3>Customer Details:</h3>
            <div class="row">
                <div class="col-md-5 text-left">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>User Name :</b>
                        </div>
                        <div class="col-md-8">
                            {{ object.billing_name }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>Mobile :</b>
                        </div>
                        <div class="col-md-8">
                            {{ object.billing_mobile }}
                        </div>
                    </div>
                </div>
                <div class="col-md-7 text-left">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>Address :</b>
                        </div>
                        <div class="col-md-8"> -->
    <!-- {{ object.address_id.address_line_1 }}, {{ object.address_id.address_line_2 }} -->
    <!-- </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>Email :</b>
                        </div>
                        <div class="col-md-8">
                            {{ object.user_email }}
                        </div>
                    </div>
                </div>
            </div>
            <hr /> -->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 customer-details-block">
      <h4>Customer Details:</h4>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="this.order.order_type == 2"
      >
        Customer :
        {{ order.shipping_name != undefined ? order.shipping_name : "" }}
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" v-else>
        Customer :
        {{ order.shipping_name != undefined ? order.shipping_name : "" }}
        <!-- {{relUserData.last_name != undefined ? relUserData.last_name : ""}} -->
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="order.order_type != 2"
      >
        <div
          style="display: inline-block; width: 100%;"
          v-show="relDelAddressData"
        >
          Address :
          {{ relDelAddressData.address_line_1 }},
          {{ relDelAddressData.address_line_2 }},
          {{ relDelAddressData.city ? relDelAddressData.city.city_name : "" }}
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" v-else>
        <div style="display: inline-block;width: 100%;">
          Address :
          {{ addressOrderType2 != undefined ? "  " + addressOrderType2 : "" }}
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="relUserData.fullObj.payment_type === 1"
      >
        Payment Method : Supiri Pola Credit
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="relUserData.fullObj.payment_type === 2"
      >
        Payment Method : Cash On Delivery
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="relUserData.fullObj.payment_type === 3"
      >
        Payment Method : Bank Transfer
      </div>
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
        v-if="relUserData.fullObj.payment_type === 4"
      >
        Payment Method : Card Payment
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 deladdress-blc">
        Mobile : {{ order.shipping_mobile }}
      </div>
      <div
        v-if="relUserData.secondary_mobile"
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4 deladdress-blc"
      >
        Home Number : {{ relUserData.secondary_mobile }}
      </div>
      <div
        v-if="!relUserData.secondary_mobile"
        class="col-lg-4 col-md-4 col-sm-4 col-xs-4 deladdress-blc"
      >
        Home Number : Not Available
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 deladdress-blc">
        Email : {{ relUserData.email }}
      </div>
    </div>

    <div class="row">
      <div
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        style="padding-top: 12px;"
      >
        <h2 class="title" style="margin-top: 20px;">
          Order Id: {{ relOrderId }}
        </h2>
        <div>Date: &nbsp;{{this.created_date}}</div>
        <div style="margin-bottom: 10px;">Time: &nbsp;{{this.created_time}}</div>
        <table id="example" class="table table-striped table-border-out">
          <thead>
            <tr>
              <th class="head-table">Image</th>
              <th class="head-table">Order ID</th>
              <th class="head-table">Product Name</th>
              <th class="head-table">Date</th>
              <th class="head-table">Quantity</th>
              <th class="head-table">Unit Price</th>
              <th class="head-table">Total Charge</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item1, index1) in object.order_lines" :key="index1">
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                <img
                  class="img-thumbnail"
                  width="70px"
                  :src="item1.simple_product_id.images[0].image"
                />
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                {{ item1.order_env_number }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                {{ item1.product_name }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                {{ item1.date }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                {{ item1.qty }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                <b>Rs. </b>{{ item1.unit_price }}
              </td>
              <td
                class="text-right"
                style="vertical-align: middle; text-align: left;"
              >
                <b>Rs. </b>{{ item1.unit_price * item1.qty.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div id="table-content" class="col-sm-12" style="">
          <h4 class="title">Delivery Partner Details</h4>
          <table id="example" class="table table-striped table-border-out merch--cancelledord">
            <thead>
              <tr>
                <th scope="col" class="head-table " style="text-align: center;">
                  Company Name
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Charge
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Email Address
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Mobile Number
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in delivery_partners" :key="index">
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.company_name }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  Rs. {{ item.charge }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.email }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.mobile }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div id="table-content" class="col-sm-12">
          <h4 class="title" style="    margin-top: 20px; margin-bottom: 20px;">
            Outlet Details
          </h4>
          <table id="example" class="table table-striped table-border-out merch--cancelledord--outlet">
            <thead>
              <tr>
                <th scope="col" class="head-table " style="text-align: center;">
                  Outlet Name
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Email Address
                </th>
                <th scope="col" class="head-table" style="text-align: center;">
                  Mobile Number
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in outletDataView" :key="index">
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.simple_product_id.product_id.store.store_name }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.simple_product_id.product_id.store.email }}
                </td>
                <td
                  class="text-center"
                  style="vertical-align: middle; text-align: center; font-weight: 700;"
                >
                  {{ item.simple_product_id.product_id.store.phone }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row" style="margin-left: 0px; margin-right:0px;">
      <div class="bottom-form">
        <!-- <div class="row" style="margin-left: 0px; margin-right:0px;">		
            <div class="col-sm-12">
              <div class="col-sm-4">
              </div>
              <div class="col-sm-2">
                <label>Items</label>
                <h4>{{ orderLines.length!= undefined ? orderLines.length : "" }}</h4>
              </div>

              <div class="col-sm-2">
                <label>Sub Total</label>
                <h4>Rs. {{ subtotal }}</h4>
              </div>

              <div class="col-sm-2">
                <label>Shipping and Handling fee</label>
                 <h4>Rs. {{ order.total_delivery_charge!= undefined ? order.total_delivery_charge : ""}}</h4>
              </div>

              <div class="col-sm-2">
                <label>Total</label>
                 <h4>Rs. {{ order.total_charge != undefined ? order.total_charge : " "  }}</h4>
              </div>
            </div>
        </div> -->

        <!-- New -->
        <div class="row" style="margin-left: 0px;margin-right: 0px;">
          <div
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
            style="padding-left: 0px;padding-right: 0px;"
          >
            <table class="table merch--cancelledord--totvalues">
              <thead>
                <tr>
                  <th>Items In Cart</th>
                  <th>Sub Total</th>
                  <th>Shipping and Handling Fee</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: center;">
                    <h4>
                      {{
                        orderLines.length != undefined ? orderLines.length : ""
                      }}
                    </h4>
                  </td>
                  <td style="text-align: center;">
                    <h4>Rs. {{ subtotal }}</h4>
                  </td>
                  <td style="text-align: center;">
                    <h4>
                      Rs.
                      {{
                        order.total_delivery_charge != undefined
                          ? order.total_delivery_charge
                          : ""
                      }}
                    </h4>
                  </td>
                  <td style="text-align: center;">
                    <h4>
                      Rs.
                      {{
                        order.total_charge != undefined
                          ? order.total_charge
                          : " "
                      }}
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <hr v-if="object.payment_type === 3" />
    <h3 v-if="object.payment_type === 3">Payment Details</h3>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-left">
        <!-- <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-5">
                            <b>Payment Method :</b>
                        </div>
                        <div class="col-md-6">
                            Bank Transfer
                        </div>
                    </div><br/> -->

        <div class="row" v-if="object.payment_type === 3">
          <div class="col-md-1"></div>
          <div class="col-md-5">
            <B>Bank Slip:</B>
          </div>
          <div class="col-md-6">
            <a :href="object.payment_image" target="_blank">
              <img
                slot="image"
                class="card-img-top"
                :src="object.payment_image"
                alt="Card image cap"
                style="cursor: pointer"
              />
            </a>
          </div>
        </div>
        <br />
        <!-- <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-5">
                            <b>Approve Stautus</b><br />
                            <select v-model="approveStatus" style="display: inline-block; width: 430px; margin-right: 18px; margin-left: 5px" class="form-control select-arrow">
                                <option v-for="item2 in statusArray" :key="item2.key" :value="item2.value">{{item2.text}}</option>
                            </select><br />
                        </div>
                    </div> -->
      </div>
      <!-- <div class="col-md-7 text-left">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <b>Total Charge :</b>
                        </div>
                        <div class="col-md-8">
                            <b>Rs. </b>{{ object.total_charge }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-6">
                            <br /> -->
      <!-- <button type="button" class="btn btn-sm btn-primary" style="background-color: #ffd338; border: none;" @click="handleStatusChange(object._id, approveStatus)">Approv</button>&nbsp; -->
      <!-- </div>
                    </div>
                </div> -->
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <!-- <button type="button" class="btn btn-sm merchprof-sbtn"  @click="handleStatusChange(object._id, approveStatus)" :disabled="object.rejectedOrd">Submit</button> &nbsp;&nbsp;&nbsp; -->
        <button
          type="button"
          class="btn btn-sm merchprof-sbtn"
          @click="handleBack()"
        >
          Back
        </button>
      </div>
    </div>
    <br />
  </div>
</template>
<script>
import mixinStore from "@/mixins/merchant/APIStore";
import mixinOrder from "@/mixins/merchant/APIOrder";
export default {
  mixins: [mixinStore, mixinOrder],
  data: function() {
    return {
      breadcrumb: [
        {
          text: "Cancelled Orders",
          href: "/merchant-cancelled-order",
          active: false,
        },
        {
          text: "Orders Configure",
          active: false,
        },
      ],
      statusArray: [
        {
          key: 1,
          text: "Approve",
          value: true,
        },
        {
          key: 2,
          text: "Reject",
          value: false,
        },
      ],
      approveStatus: true,
      object: {},
      showSubComp: 0,
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      searchKeyStatus: "all",
      tableItems: [],
      blockLoading: true,
      paymentFilteroptions: [
        { text: "All", value: "all" },
        { text: "Bank Transfer", value: "banktransfer" },
        { text: "Credit Card", value: "creditcard" },
      ],
      relUserData: {},
      relDelAddressData: "",
      addressOrderType2: "",
      relOrderId: "",
      order: {},
      outletDataView: [],
      orderLines: [],
      subtotal: 0,
      delivery_partners: [],
      orderLines: [],
      created_time: "",
      created_date: "",
    };
  },
  watch: {
    searchKey: function(val) {
      let sType = "orderid";
      this.searchCustomers(val, sType);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchCustomers(this.searchKey);
    },
  },
  created() {
    this.handleBreadcrumb();
    this.handleGetOrderList();
    this.getSingleOrderDetails();
  },
  methods: {
    getSingleOrderDetails: async function() {
      var orderId = this.$route.params.id
        ? this.$route.params.id
        : localStorage.cancelled_order_group_id;
      try {
        let response = await this.getOrderLinesByOrderId(orderId);
        this.object = response;

        if (
          this.object.admin_approve == false &&
          this.object.order_status == 0
        ) {
          this.object.rejectedOrd = true;
        } else {
          this.object.rejectedOrd = false;
        }


        var orderLinesArray = [];
        var orderLinesArray = response.order_lines;
        this.orderLines = response.order_lines;
        this.order = response;

        this.created_date = response.order_lines[0].date;
        this.created_time = moment(response.order_lines[0].created_date).format("hh:mm A");

        // let time = new Date(response.order_lines[0].created_date);
        // let createdHours = new Date(time).getHours();
        // let createdMinutes =  new Date(time).getMinutes();
        // let createdSeconds =  new Date(time).getSeconds();
        // this.created_time =  createdHours + ":" + createdMinutes + ":" + createdSeconds;
        // this.created_time = response.order_lines[0].created_date.split("T")[1].split(".0")[0];

        if (this.order.order_type == 2) {
          this.relUserData.fullObj = this.order;
          this.relUserData.name = this.order.shipping_name;
          this.relUserData.mobile = this.order.shipping_mobile;
          this.relUserData.email = this.order.user_email;
          this.relOrderId = this.order.order_env_number;
          this.addressOrderType2 = this.order.robo_shipping_address;
        } else {
          this.relOrderId = response.order_env_number;
          this.relUserData = response.user_id;
          this.relUserData.fullObj = response;
          this.relDelAddressData = response.address_id;
        }

        // Check duplicate outlet data & fetch
        var displayOutlets = [];
        var outlets = [];
        for (var i = 0, len = orderLinesArray.length; i < len; i++) {
          if (
            outlets.indexOf(
              orderLinesArray[i].simple_product_id.product_id.store._id
            ) > -1
          ) {
            // console.log('Duplicate type');
          } else {
            // console.log('New type');
            outlets.push(
              orderLinesArray[i].simple_product_id.product_id.store._id
            );
            displayOutlets.push(orderLinesArray[i]);
          }
        }
        this.outletDataView = displayOutlets;

        this.populateDeliveryPartners();
        this.calculateSubtotal();
      } catch (error) {
        throw error;
      }
    },
    populateDeliveryPartners: function() {
      this.delivery_partners = [];
      let dp_arraytxt = [];
      let dp_charges = [];
      for (let index = 0; index < this.orderLines.length; index++) {
        const element = this.orderLines[index];
        let delivery_partner = {
          company_name: element.delivery_partner.company_name,
          mobile: element.delivery_partner.mobile,
          email: element.delivery_partner.email,
          _id: element.delivery_partner._id,
        };
        dp_arraytxt.push(JSON.stringify(delivery_partner));
        dp_charges.push({
          _id: element.delivery_partner._id,
          charge: element.delivery_charge,
        });
      }

      var uniquedp = dp_arraytxt.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      });

      for (let index = 0; index < uniquedp.length; index++) {
        const element = uniquedp[index];
        let del_charge = 0;
        let obj = JSON.parse(element);
        dp_charges.forEach((element) => {
          if (element._id == obj._id) {
            del_charge = element.charge + del_charge;
          }
        });
        obj.charge = del_charge;
        this.delivery_partners.push(obj);
      }
    },
    calculateSubtotal: function() {
      this.subtotal = 0;
      this.orderLines.forEach((item) => {
        this.subtotal = item.unit_price * item.qty + this.subtotal;
      });
    },
    filterOrderPayType: function() {
      let sType = "pay";
      this.searchCustomers(this.searchKeyStatus, sType);
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    handleGetOrderList: async function() {
      try {
        this.tableItems = [];
        this.storeListData = [];
        this.blockLoading = true;

        this.storeListData = await this.getOrderList();

        // Check order list & filter out rejected order
        var pendingToApproveRejOrders = [];
        pendingToApproveRejOrders = this.storeListData.filter((item) => {
          return item.admin_approve == false && item.order_status != 0;
        });

        // Set item count for orders
        pendingToApproveRejOrders.forEach((obj) => {
          obj.itemCount = obj.order_lines.length;
        });


        this.storeListData = pendingToApproveRejOrders;

        this.searchCustomers("");
        this.blockLoading = false;
      } catch (error) {
        this.$toast.error("Could not get details!", "Error", {
          position: "topRight",
        });
      }
    },
    searchCustomers: function(keyword, type = "") {
      if (type == "pay") {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter((obj) => {
              {
                if (keyword == "banktransfer") {
                  return obj.payment_type == 3;
                }

                if (keyword == "creditcard") {
                  return obj.payment_type == 4;
                }

                if (keyword == "all") {
                  return obj;
                }
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        }
      } else if ("orderid") {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter((obj) => {
              {
                return (
                  obj.order_env_number && obj.order_env_number.includes(keyword)
                );
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        }
      } else {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter((obj) => {
              {
                return (
                  obj.order_env_number && obj.order_env_number.includes(keyword)
                );
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        }
      }
    },
    handleBack() {
      this.object = {};
      // this.showSubComp = 0;
      this.$router.push({ name: "Merchant Cancelled Order" });
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if (currentPage === 1) {
        startsize = 0;
      } else {
        startsize = endsize - pagesize;
      }
      this.tableItems = this.storeListData.slice(startsize, endsize);
    },
    handleStatusChange: async function(id, condition) {
      let aproveObj = {};
      aproveObj._id = id;
      aproveObj.status = condition;
      if (condition === true) {
        try {
          await this.changeStatusOrder(aproveObj);
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Successfully Approved!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push({ path: "/merchant-pending-order" });
        } catch (error) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Couldn't Approved!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.handleReject(aproveObj);
      }
      await this.handleGetOrderList();
    },
    handleReject: async function(obj) {
      try {
        let self = this;
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, reject it!",
        }).then((result) => {
          if (result.value) {
            self.rejectOrderDetail(obj);
            this.$swal("Reject!", "Order has been reject.", "success");
          }
        });
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Couldn't Reject!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    rejectOrderDetail: async function(value) {
      try {
        await this.changeStatusOrder(value);
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Couldn't Reject!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    handleView(obj) {
      this.object = {};
      this.showSubComp = 1;
      this.object = obj;

      localStorage.setItem("pending_order_group_id", obj._id);
      this.$router.push({
        name: "Pending Order Lines",
        params: { id: obj._id },
      });
    },
  },
};
</script>
<style>
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 265px;
}
.head-table {
  background: white !important;
  text-align: left;
}
.table-border-out {
  border: 1px solid #ddd;
}
.merchprof-sbtn {
  background: #ffd338;
  color: #000000;
  border: none;
  font-size: 14px;
}
.merchprof-sbtn:hover {
  color: #ffffff;
}
.customer-details-block {
  padding-top: 10px;
  padding-bottom: 10px;
}
.customer-details-block .innercus {
  background: #eeeeee;
}
.merch--cancelledord{
  width: 100% !important;
}
.merch--cancelledord--outlet{
  width: 100% !important;
}
.merch--cancelledord--totvalues{
  width: 100% !important;
}
</style>
